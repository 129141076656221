<template>
  <!-- 区域地图 -->
  <div class="areaMap">
    <div class="all-institutions">
      <secondaryMap ref="secondaryMap" :region="region" />
    </div>
  </div>
</template>
<script>
import secondaryMap from '@/components/secondaryMap'
import dachuanData from '@/assets/dachuanData/index.js'
export default {
  components: { secondaryMap },
  data() {
    return {
      region: '',
      punctuationDataDs: dachuanData.ds_data.punctuationData,
      punctuationDataCg: dachuanData.gc_data.punctuationData,
    }
  },
  computed: {
    userInfo() {
      return JSON.parse(sessionStorage.getItem('statistics-gov-userInfo'))
    },
    type() {
      return this.$route.query.type
    },
    newTypes() {
      return this.$route.query.storeType
    },
  },
  created() {
    this.region =
      this.userInfo.province + this.userInfo.city + this.userInfo.area
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      let arr = {}
      if (this.type == 'ds') {
        console.log(11111111)
        arr = [
          {
            name: this.punctuationDataDs.name,
            area: this.punctuationDataDs.area,
            spName: this.punctuationDataDs.spName,
            percent: this.punctuationDataDs.percent,
            lng: this.punctuationDataDs.lng,
            lat: this.punctuationDataDs.lat,
            storeType: this.newTypes,
          },
        ]
      } else {
        arr = [
          {
            name: this.punctuationDataCg.name,
            area: this.punctuationDataCg.area,
            spName: this.punctuationDataCg.spName,
            percent: this.punctuationDataCg.percent,
            lng: this.punctuationDataCg.lng,
            lat: this.punctuationDataCg.lat,
            storeType: this.newTypes,
          },
        ]
      }
      this.$refs.secondaryMap.initializeMap(arr)
    },
  },
}
</script>
<style scoped>
.all-institutions {
  background: url('~@/assets/images/institution_images/mapback.png') 0 0
    no-repeat;
  background-size: 100% 100%;
  height: 30rem;
  margin-bottom: 0.3rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.fist-item {
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #ff8529;
}
.chartBox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
}
.flag-i {
  position: absolute;
  right: 0.5rem;
  bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.flag-i > div:first-child {
  margin-right: 0.3rem;
}
.flag-text {
  padding: 0.2rem 0.5rem 0.45rem;
  text-align: center;
  background: rgba(2, 11, 40, 0.5);
  border-radius: 2px;
  border: 1px solid #213060;
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8eb8e6;
}
.region,
.map-box {
  width: 100%;
  height: 100%;
}
.regionC {
  width: 100%;
  height: 100%;
}
</style>
<style>
.map-tools {
  padding: 0.3rem 0.5rem 0.5rem 0.5rem;
  background: url('~@/assets/images/institution_images/fukuang.png') 0 0
    no-repeat;
  background-size: 100% 100%;
}
.map-tools > div:first-child {
  white-space: normal;
  font-size: 0.6rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
.map-tools-item > .operation-unit {
  white-space: normal;
  word-break: break-all;
  text-align: left;
}
.map-tools-item {
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8eb8e6;
}
.map-tools-item > span:first-child {
  display: inline-block;
  width: 60px;
  text-align: right;
}
.map-tools-num {
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ff8529;
}
</style>
