/**
 *
 *
 * 达州市达川区渡市中心敬老院
 *
 *
 */
const ds_data = {
  // 敬老院基本信息
  punctuationData: {
    insNo: '',
    name: '达州市达川区渡市中心敬老院',
    area: '87.7平方公里',
    spName: '达州市达川区渡市镇人民政府',
    percent: '43',
    lng: '107.287072',
    lat: '31.068188',
    storeType: '1',
  },
  // 社区内老龄人口健康管理信息
  dataList: {
    managementInfo: {
      elderlyPopulationHealthCount: 6592, // 社区内老龄人口健康档案建档人数
      totalRatio: 100, // 占比总人数
      diseases: [
        {
          diseaseName: '高血压',
          diseaseCount: 386,
          diseaseRatio: '5.58%',
        },
        {
          diseaseName: '脑梗',
          diseaseCount: 324,
          diseaseRatio: '4.91%',
        },
        {
          diseaseName: '糖尿病',
          diseaseCount: 263,
          diseaseRatio: '4.32%',
        },
        {
          diseaseName: '肺气肿',
          diseaseCount: 190,
          diseaseRatio: '2.88%',
        },
        {
          diseaseName: '关节炎',
          diseaseCount: 152,
          diseaseRatio: '2.31%',
        },
      ],
    },
  },
  // 区域内人口基本信息
  oldData: {
    olderInfo: {
      streetPopulationCount: 20421, // 街道人口总数
      elderPopulationCount: 6592, //  老龄人口总数
      elderRatio: 32.28, // 老龄化比例
      seniorPopulationCount: 1978, // 高龄老人数量
      seniorRatio: '30%', // 高龄老人比例
      singlePopulationCount: 23, // 孤寡老人数量
      singleRatio: '0.3%', //  孤寡老人比例
      disablePopulationCount: 98, // 残障老人数量
      disableRatio: '5%', // 残障老人比例
      poorPopulationCount: 167, // 贫困老人数量
      poorRatio: '2.5%', // 贫困老人比例
    },
  },
  // 社区活动开展情况
  listData: [
    {
      startTime: '2023-07-01 10:28:26', // 开展日期
      category: '免费体检', // 活动类型
      topic: '65岁以上老年人健康服务体检', // 课程主题
      num: '2011', // 参加人数
    },
    {
      startTime: '2023-08-01 10:28:26', // 开展日期
      category: '免费体检', // 活动类型
      topic: '65岁以上老年人健康服务体检', // 课程主题
      num: '1817', // 参加人数
    },
    {
      startTime: '2023-09-01 10:28:26', // 开展日期
      category: '免费体检', // 活动类型
      topic: '65岁以上老年人健康服务体检', // 课程主题
      num: '1625', // 参加人数
    },
  ],
  // 年度体检
  yearDataList: {
    examinationInfo: {
      inspect: [12, 15, 17, 11, 15, 14, 19, 13, 15, 16, 9, 0], // 体检人数
      purchase: [36, 45, 51, 33, 45, 42, 57, 39, 45, 48, 27, 0], // 服务人数
    },
  },
  // 助餐留样
  sampleData: [
    {
      inspectItem: '午餐',
      sampleKeepTime: '2023-12-21 12:15',
      grade: '125g',
      num: '4',
      sampleKeeper: '常兰云',
    },
  ],
  // 安全信息
  safetyData: [
    {
      name: '燃气', // 安全名称
      status: '正常', // 状态
      inspector: '冉隆梅', // 巡检人
    },
  ],
  // 社区内社区养老服务信息
  aggregateMessage: {
    total: 0, //社区敬老卡会员人数
    pointTotal: 0, //积分养老统计总分
    pointCashTotal: 0, //积分养老累计兑换总分
    services: [
      {
        key: '助餐',
        value: 110,
      },
      {
        key: '助医',
        value: 100,
      },
      {
        key: '娱乐',
        value: 95,
      },
      {
        key: '助洁',
        value: 65,
      },
    ], // 社区服务前10名
  },
  // 床位信息
  bedMessage: {
    total: 130, // 总床位数
    nurseTotal: 70, //护理床位数
    nursePercent: '53.84%', //护理床位占比
    incomeTotal: 56, //已入驻人数
    incomePercent: '43%', //已入驻率
  },
  // 养老服务实时数据
  realtime: {
    total: 110, //累计服务人数
    amount: 3740, //累计服务金额
    currCustomer: 3, // 今日在线服务客户
    currWorker: 3, //今日在线服务人员
    lists: [{ createTime: '2023-11-10', orderNo: '00189', name: '助餐' }], // 今日在线订单显示
  },
}

/**
 *
 *
 * 达州市达川区管村中心敬老院
 *
 *
 */

const gc_data = {
  // 敬老院基本信息
  punctuationData: {
    insNo: '',
    name: '达州市达川区管村中心敬老院',
    area: '2241平方米',
    spName: '达州市达川区管村镇人民政府',
    percent: '48',
    lng: '107.333934',
    lat: '31.193497',
    storeType: '1',
  },
  // 社区内老龄人口健康管理信息
  dataList: {
    managementInfo: {
      elderlyPopulationHealthCount: 9176, // 社区内老龄人口健康档案建档人数
      totalRatio: 100, // 占比总人数
      diseases: [
        {
          diseaseName: '高血压',
          diseaseCount: 757,
          diseaseRatio: '8.24%',
        },
        {
          diseaseName: '糖尿病',
          diseaseCount: 657,
          diseaseRatio: '7.16%',
        },
        {
          diseaseName: '关节炎',
          diseaseCount: 409,
          diseaseRatio: '4.46%',
        },
      ],
    },
  },
  // 区域内人口基本信息
  oldData: {
    olderInfo: {
      streetPopulationCount: 31591, // 街道人口总数
      elderPopulationCount: 9176, //  老龄人口总数
      elderRatio: 29, // 老龄化比例
      seniorPopulationCount: 206, // 高龄老人数量
      seniorRatio: '0.65%', // 高龄老人比例
      singlePopulationCount: 12, // 孤寡老人数量
      singleRatio: '0.038%', //  孤寡老人比例
      disablePopulationCount: 171, // 残障老人数量
      disableRatio: '0.54%', // 残障老人比例
      poorPopulationCount: 56, // 贫困老人数量
      poorRatio: '0.18%', // 贫困老人比例
    },
  },
  // 社区活动开展情况
  listData: [
    // {
    //   startTime: '2021-01-01 10:28:26', // 开展日期
    //   category: 'sddasd', // 活动类型
    //   topic: 'gfgfgfg', // 课程主题
    //   num: '26', // 参加人数
    // },
  ],
  // 年度体检
  yearDataList: {
    examinationInfo: {
      inspect: [36, 35, 30, 33, 35, 36, 36, 35, 34, 34, 0, 0], // 体检人数
      purchase: [108, 105, 90, 99, 105, 108, 108, 105, 72, 72, 0, 0], // 服务人数
    },
  },
  // 助餐留样
  sampleData: [
    {
      inspectItem: '蒸肉',
      sampleKeepTime: '2023-12-21 12:20',
      grade: '130g',
      num: '4',
      sampleKeeper: '庞小梅',
    },
  ],
  // 安全信息
  safetyData: [
    {
      name: '食品安全', // 安全名称
      status: '正常', // 状态
      inspector: '熊芳', // 巡检人
    },
    {
      name: '消防安全', // 安全名称
      status: '正常', // 状态
      inspector: '熊芳', // 巡检人
    },
  ],
  // 社区内社区养老服务信息
  aggregateMessage: {
    total: 0, //社区敬老卡会员人数
    pointTotal: 0, //积分养老统计总分
    pointCashTotal: 0, //积分养老累计兑换总分
    services: [
      // {
      //   key: '洗脚服务',
      //   value: 9,
      // },
      // {
      //   key: '泡澡五福',
      //   value: 8,
      // },
    ], // 社区服务前10名
  },
  // 床位信息
  bedMessage: {
    total: 77, // 总床位数
    nurseTotal: 42, //护理床位数
    nursePercent: '54.5%', //护理床位占比
    incomeTotal: 36, //已入驻人数
    incomePercent: '47%', //已入驻率
  },
  // 养老服务实时数据
  realtime: {
    total: 0, //累计服务人数
    amount: 0, //累计服务金额
    currCustomer: 0, // 今日在线服务客户
    currWorker: 0, //今日在线服务人员
    lists: [], // 今日在线订单显示
  },
}

export default {
  gc_data,
  ds_data,
}
