<template>
  <!-- 安全信息-公共社区实时监控 -->
  <div class="safety-monitoring">
    <div class="monitoring">
      <div style="height: 8.65rem">
        <div class="video-cs">
          <img
            v-if="this.$route.query.type == 'gc'"
            src="@/assets/images/guancun.jpg"
            alt=""
          />
          <img v-else src="@/assets/images/dszx.jpg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },

  methods: {},
}
</script>

<style scoped lang="scss">
.safety-monitoring {
  display: flex;
}
.safety > div:first-child {
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #ffd725;
  line-height: 2rem;
  text-indent: 1rem;
}
.safety {
  flex: 1;
  background-size: 100% 100%;
}
.mask {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}
.monitoring {
  flex: 1;
  background: url('~@/assets/images/institution_images/monitoring.png') 0 0
    no-repeat;
  background-size: 100% 100%;
  position: relative;
  padding: 1.76rem 0 0.5rem 0;
  box-sizing: border-box;
  margin-top: 0.3rem;
  cursor: pointer;
}
.monitoring > div:first-child {
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #ffd725;
  line-height: 2rem;
  text-indent: 1rem;
}
.csdul-cs {
  height: 8.2rem;
}
.csdul-cs > li {
  height: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.csdul-cs > li > span:first-child {
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #00d5ff;
  background-size: 100% 100%;
  height: 1rem;
  width: 20%;
  font-size: 0.55rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.csdul-cs > li > div:nth-child(2) > span {
  padding: 0.15rem 0.15rem;
  background-size: 100% 100%;
  margin-right: 0.2rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.csdul-cs > li > div:nth-child(3) {
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #27d2f4;
}
.video-cs {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 8.6rem;
  img {
    width: 500px;
    height: 233px;
  }
}
#iframe {
  border: 0px;
  vertical-align: bottom;
  background: #3d3d3d;
}
.noMessage {
  position: absolute;
  left: 8.8rem;
  top: 4.4rem;
  font-size: 1.2rem;
  color: #2d3769;
}
</style>
